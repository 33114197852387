// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body[data-theme="dark"] {
  --color-bg:#62254d;
  --color-fg: white;
  --color-button-bg: #FDCB1E;
  --color-button-fg: black;
  --color-button-hover: white;
  --color-button-hover-text: black;
  --color-modal-bg: #872932;
  --color-modal-bg2: #872932;
  --color-faq:  #E7B268;
  --color-modal-button: #FDCB1E;
  --color-border:transparent;
  --color-item: #eaa2c0;
  --color-toggle: white;
}

body[data-theme="light"] {
  --color-bg: #b4c7e7;
  --color-fg: #000000;
  --color-button-bg: #b4c7e7;
  --color-button-fg: white;
  --color-button-hover: white;
  --color-button-hover-text: black;
  --color-modal-bg: #b4c7e7;
  --color-modal-bg2: #594B6F;
  --color-faq:  #594B6F;
  --color-modal-button: #594B6F;
  --color-border:black;
  --color-item: #b4c7e7;
  --color-toggle: #b4c7e7;
}

body {
  data-theme:"dark";
  margin: 0;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background: var(--color-bg);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  margin: 0;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,0BAA0B;EAC1B,wBAAwB;EACxB,2BAA2B;EAC3B,gCAAgC;EAChC,yBAAyB;EACzB,0BAA0B;EAC1B,qBAAqB;EACrB,6BAA6B;EAC7B,0BAA0B;EAC1B,qBAAqB;EACrB,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,0BAA0B;EAC1B,wBAAwB;EACxB,2BAA2B;EAC3B,gCAAgC;EAChC,yBAAyB;EACzB,0BAA0B;EAC1B,qBAAqB;EACrB,6BAA6B;EAC7B,oBAAoB;EACpB,qBAAqB;EACrB,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;EACjB,SAAS;EACT,iBAAiB;EACjB;;cAEY;EACZ,2BAA2B;AAC7B;;AAEA;EACE;aACW;AACb;;AAEA;EACE,SAAS;EACT,UAAU;AACZ","sourcesContent":["body[data-theme=\"dark\"] {\n  --color-bg:#62254d;\n  --color-fg: white;\n  --color-button-bg: #FDCB1E;\n  --color-button-fg: black;\n  --color-button-hover: white;\n  --color-button-hover-text: black;\n  --color-modal-bg: #872932;\n  --color-modal-bg2: #872932;\n  --color-faq:  #E7B268;\n  --color-modal-button: #FDCB1E;\n  --color-border:transparent;\n  --color-item: #eaa2c0;\n  --color-toggle: white;\n}\n\nbody[data-theme=\"light\"] {\n  --color-bg: #b4c7e7;\n  --color-fg: #000000;\n  --color-button-bg: #b4c7e7;\n  --color-button-fg: white;\n  --color-button-hover: white;\n  --color-button-hover-text: black;\n  --color-modal-bg: #b4c7e7;\n  --color-modal-bg2: #594B6F;\n  --color-faq:  #594B6F;\n  --color-modal-button: #594B6F;\n  --color-border:black;\n  --color-item: #b4c7e7;\n  --color-toggle: #b4c7e7;\n}\n\nbody {\n  data-theme:\"dark\";\n  margin: 0;\n  font-weight: bold;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  background: var(--color-bg);\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n*{\n  margin: 0;\n  padding: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
