// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.registry-placeholder{
    text-align: left;
}
.registry-wrap-mob{

    /*background-color: #872932;*/
    border-radius: 5px;
    padding: 2% 3%;
}

button[type=registry] {
    background-color: var(--color-button-bg);
    color: black;
    padding: 12px 20px;
    border: 1px solid var(--color-border);
    border-radius: 4px;
    cursor: pointer;
    scale: 1.25;
}
button[type=registry]:hover {
    color: black;
    background-color: #EC3F13;
}
`, "",{"version":3,"sources":["webpack://./src/styles/registry.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;;IAEI,6BAA6B;IAC7B,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,wCAAwC;IACxC,YAAY;IACZ,kBAAkB;IAClB,qCAAqC;IACrC,kBAAkB;IAClB,eAAe;IACf,WAAW;AACf;AACA;IACI,YAAY;IACZ,yBAAyB;AAC7B","sourcesContent":[".registry-placeholder{\n    text-align: left;\n}\n.registry-wrap-mob{\n\n    /*background-color: #872932;*/\n    border-radius: 5px;\n    padding: 2% 3%;\n}\n\nbutton[type=registry] {\n    background-color: var(--color-button-bg);\n    color: black;\n    padding: 12px 20px;\n    border: 1px solid var(--color-border);\n    border-radius: 4px;\n    cursor: pointer;\n    scale: 1.25;\n}\nbutton[type=registry]:hover {\n    color: black;\n    background-color: #EC3F13;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
