// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FAQS_root__nliLa {
    font-size: xxx-large;
    border: 1px transparent;
}
.FAQS_item__hIgMu{
    color: black;
    border: 1px transparent;
    outline: 2px solid var(--color-border);
    font-size: xx-large;
    text-align: left;
    background-color: var(--color-item);
    border-radius: 10px;
    margin-bottom: 20px;
    margin-left: 5%;
    margin-right: 5%;
}
.FAQS_control__eNT7v{
    border: 1px transparent;
    outline: 2px solid var(--color-border);
    border-radius: 10px;
    background-color: var(--color-modal-bg);
    color: var(--color-fg);
    font-size: xx-large;
    &[data-active] {
        color: black;
        background-color: white;
    }
}
.FAQS_control__eNT7v:hover{
    border: 1px transparent;
    color: black;
    font-size: xx-large;
}
`, "",{"version":3,"sources":["webpack://./src/styles/FAQS.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,uBAAuB;AAC3B;AACA;IACI,YAAY;IACZ,uBAAuB;IACvB,sCAAsC;IACtC,mBAAmB;IACnB,gBAAgB;IAChB,mCAAmC;IACnC,mBAAmB;IACnB,mBAAmB;IACnB,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,uBAAuB;IACvB,sCAAsC;IACtC,mBAAmB;IACnB,uCAAuC;IACvC,sBAAsB;IACtB,mBAAmB;IACnB;QACI,YAAY;QACZ,uBAAuB;IAC3B;AACJ;AACA;IACI,uBAAuB;IACvB,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":[".root {\n    font-size: xxx-large;\n    border: 1px transparent;\n}\n.item{\n    color: black;\n    border: 1px transparent;\n    outline: 2px solid var(--color-border);\n    font-size: xx-large;\n    text-align: left;\n    background-color: var(--color-item);\n    border-radius: 10px;\n    margin-bottom: 20px;\n    margin-left: 5%;\n    margin-right: 5%;\n}\n.control{\n    border: 1px transparent;\n    outline: 2px solid var(--color-border);\n    border-radius: 10px;\n    background-color: var(--color-modal-bg);\n    color: var(--color-fg);\n    font-size: xx-large;\n    &[data-active] {\n        color: black;\n        background-color: white;\n    }\n}\n.control:hover{\n    border: 1px transparent;\n    color: black;\n    font-size: xx-large;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `FAQS_root__nliLa`,
	"item": `FAQS_item__hIgMu`,
	"control": `FAQS_control__eNT7v`
};
export default ___CSS_LOADER_EXPORT___;
