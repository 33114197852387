// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Draw_root__sQs1T {
    color: black;
    background: #62254D;
}

.Draw_content__5FIpj {
    color: black;
    background: #62254D;
}`, "",{"version":3,"sources":["webpack://./src/styles/Draw.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":[".root {\n    color: black;\n    background: #62254D;\n}\n\n.content {\n    color: black;\n    background: #62254D;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Draw_root__sQs1T`,
	"content": `Draw_content__5FIpj`
};
export default ___CSS_LOADER_EXPORT___;
